import React from "react";

import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import useWidth from "hooks/useWidth";
import { ObjectId } from "mongoose";
import { FieldsMenu } from "components/FieldsMenu";
import { AppConfig } from "navision-proxy-api/@types";

import _ from "lodash";
import moment from "moment";
//import { deleteQuickForm } from "api";

import { Validator } from "utils/validators";

import { useStore } from "context/store";
import { useLang } from "context/lang";

import ButtonsView from "containers/QuickForm/ButtonsView";
import AddQuickFormBooking from "containers/AddQuickFormBooking";
import { BookingForm } from "components/BookingForm";
import { ChooseMenu } from "components/ChooseMenu";
import { Loader } from "components/Loader";
import { useStyles } from "styles";
import { useSnackbar } from "context/snackbar";
import { useFieldsSettings } from "context/fieldsSettings";
import { useBookings } from "hooks/bookings";

import TableForm from "containers/QuickForm/TableForm";
import ItemsForm from "containers/QuickForm/ItemsForm";
import { useQuickForm } from "hooks/quickForm";
import {
  useBookingForm,
  FormErrors,
  BookingError,
} from "hooks/bookings/bookingForm";
import { useBookingActions } from "hooks/bookings/bookingActions";
import {
  IQuickForm,
  IBooking,
  BookingFieldId,
  Lookup,
} from "navision-proxy-api/@types";
import { ReorderQuickFormRequest } from "navision-proxy-api/@types/api";
import { useAuth } from "context/auth";
import { Typography } from "@material-ui/core";
import { useApi } from "hooks/api";

interface IProps {
  /** Is component used for local Quick form or regular */
  local?: boolean;
  /** Fields which are filled in only once and used for all bookings templates */
  muiltiFormFields: BookingFieldId[];
  /** Fields which we are ignoring and not using in quick form template */
  ignoredColumns: BookingFieldId[];
  /** Validators for field */
  validators: { [fieldName in BookingFieldId]?: Validator[] };
}

export interface IBookingsMap {
  [bookingId: string]: IBooking;
}

export default ({
  local = false,
  muiltiFormFields = [],
  ignoredColumns = [],
  validators = {},
}: IProps) => {
  const { bookingFormData, loading: loadingBookingFormData } = useStore();
  const {
    bookings: quickFormBookings,
    loading: loadingBookings,
    loadBookings,
    reloadBookings,
  } = useBookings();

  const {
    loading: loadingQuickForms,
    quickForms,
    createQuickForm,
    deleteQuickForm,
    deleteQuickFormBooking,
    bulkUpdateQuickForm,
    releaseQuickForm,
    reloadQuickForms,
    reorderLines,
  } = useQuickForm({
    isLocal: local,
  });

  /** Form for dates since dates in quick form choosen once for all bookings templates */
  const {
    booking: bookingForm,
    bookingRef: bookingFormRef,
    initBookingForm,
    loading: loadingForm,
    handleChangeField,
    customersCustomerLookup,
    handleClearAddresses,
    setBooking,
    setEnableCCLookup,
    pushCustomersCustomer,
    errors,
    setErrors,
  } = useBookingForm();

  const { t } = useLang();

  const { openSnackbar } = useSnackbar();
  const { hiddenFields, setHiddenFields, toogleHiddenField } =
    useFieldsSettings();

  const { checkPickupDate } = useApi();

  const [currentQuickForm, setCurrentQuickForm] = React.useState<
    IQuickForm | undefined
  >(undefined);

  const [creating, setCreating] = React.useState(false);
  const [editing, setEditing] = React.useState(false); //we edit name

  const [loading, setLoading] = React.useState(false);

  const [quickFormNewName, setEditQuickFormName] = React.useState("");

  const [adding, setAdding] = React.useState(false); //we add new row

  const { user, setAppUserConfig } = useAuth();

  const [buttonsView, setButtonsViewState] = React.useState<Boolean>(
    Boolean(user?.appConfig?.defaultButtonsView)
  );

  const setButtonsView = (value: Boolean) => {
    setButtonsViewState(value);
    setAppUserConfig({ defaultButtonsView: value } as Partial<AppConfig>);
  };

  const width = useWidth();
  //we use this because we hide actual material table buttons,
  //render different buttons which are using their actions by ref

  // const confirmRef = React.useRef();
  // const cancelRef = React.useRef();

  const styles = useStyles();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (
      !currentQuickForm || //no current quick form set
      (currentQuickForm &&
        // or current quick form is from old quick form lists
        (!quickForms.map(({ _id }) => _id).includes(currentQuickForm._id) ||
          !quickForms.map(({ name }) => name).includes(currentQuickForm.name)))
    ) {
      setCurrentQuickForm(quickForms[0]);
    }
  }, [quickForms]);

  React.useEffect(() => {
    handleLoadBookings();
  }, [currentQuickForm]);

  const handleLoadBookings = () => {
    if (currentQuickForm) {
      loadBookings({ filters: { quickForm: currentQuickForm._id } });
    }
  };

  const handleCreateQuickForm = async () => {
    const qf = await createQuickForm({
      name: `${t("newQuickForm")} ${quickForms.length || ""}`,
      isLocal: local,
    } as IQuickForm);

    setCurrentQuickForm(qf);
    setEditQuickFormName(qf.name);
    setCreating(true);
    setEditing(true);
  };

  const handleDeleteQuickForm = async () => {
    await deleteQuickForm({
      ...currentQuickForm,
      isLocal: local,
    } as IQuickForm);
    if (currentQuickForm) {
      setCurrentQuickForm(
        quickForms.find((qf) => qf._id !== currentQuickForm._id)
      );
    }
    setCreating(false);
    setEditing(false);
    reloadQuickForms();
  };

  const handleEditQuickFormName = async () => {
    if (currentQuickForm) {
      await bulkUpdateQuickForm(currentQuickForm._id, quickFormNewName);
      setEditing(false);
      reloadQuickForms();
    }
  };

  const handleEditing = () => {
    if (currentQuickForm) {
      // discardAllActionRef.current.click(); //to exit from editing all
      setEditQuickFormName(currentQuickForm.name);
      setEditing(true);
    }
  };

  /** Here we want to merge dates from booking form and other data from bookings templates in table. */
  const handleSubmit = async (bookingsNewData: IBookingsMap) => {
    const dataFromBookingForm: any = Object.entries(bookingFormRef.current) //We need to use ref because handler being memoized and using old state TODO: check if it is ok
      .filter(([key]) => muiltiFormFields.includes(key as BookingFieldId))
      .reduce((acc, [key, value]) => ({ ...acc, [key]: value || "" }), {});

    //Format to local time TODO: use more universal solution because this convertion duplicated in create booking
    dataFromBookingForm.deliveryDate = dataFromBookingForm.deliveryDate
      ? moment(dataFromBookingForm.deliveryDate).utc(true).toDate()
      : "";

    dataFromBookingForm.latestDeliveryDate =
      dataFromBookingForm.latestDeliveryDate
        ? moment(dataFromBookingForm.latestDeliveryDate).utc(true).toDate()
        : "";

    dataFromBookingForm.pickupDate = dataFromBookingForm.pickupDate
      ? moment(dataFromBookingForm.pickupDate).utc(true).toDate()
      : "";

    if (
      (await validate(bookingsNewData, dataFromBookingForm)) &&
      currentQuickForm
    ) {
      setLoading(true);
      //we save the changes and create bookings
      const newQuickFormBookings = quickFormBookings?.items.map((b) => ({
        ...(bookingsNewData[String(b._id)] || b),
        ...dataFromBookingForm,
      }));

      await bulkUpdateQuickForm(
        currentQuickForm._id,
        currentQuickForm.name,
        newQuickFormBookings
      );
      await releaseQuickForm(currentQuickForm._id);
      setLoading(false);
      initBookingForm();
    }
  };

  const validate = async (
    bookingsNewData: IBookingsMap,
    dataFromBookingForm: Partial<IBooking>
  ): Promise<boolean> => {
    //validation
    const newErrors: FormErrors = {};

    //merge old data with new data
    const allBookingsDatas: IBookingsMap =
      quickFormBookings?.items.reduce(
        (acc, b) => ({
          ...acc,
          [String(b._id)]: {
            ...b,
            ...bookingsNewData[String(b._id)],
          } as IBooking,
        }),
        {}
      ) || {};
    console.log("trying to start deadline validation");

    console.log(dataFromBookingForm);

    //** Check of any bookings are after a deadline */
    if (dataFromBookingForm?.pickupDate) {
      const deliveryCountries = Object.values(allBookingsDatas).map(
        (value) =>
          customersCustomerLookup.find(
            ({ deliveryCode }) => deliveryCode === value.delivery
          )?.deliveryCountry
      );
      const uniqueDeliveryCountires = [...new Set(deliveryCountries)];

      const deadlineCheckResult = await Promise.all(
        uniqueDeliveryCountires.map(async (dc) => {
          if (dataFromBookingForm.pickupDate && dc && user?.userId) {
            const { AfterDeadline } = await checkPickupDate(
              moment(dataFromBookingForm.pickupDate).utc(true).toISOString(),
              dc,
              user?.userId
            );
            return AfterDeadline;
          }
        })
      );

      if (deadlineCheckResult.some((result) => result)) {
        newErrors["pickupDate"] = "afterDeadline";
        openSnackbar(t("bookingsAfterDeadline"), "error");
        return false;
      }
    }

    if (validators) {
      Object.keys(validators)
        .filter((field) => !ignoredColumns.includes(field as BookingFieldId)) // we don't need to validate ignored columns
        .forEach((field) => {
          const fieldValidators = validators[field as BookingFieldId];

          if (fieldValidators) {
            for (let validator of fieldValidators) {
              if (field in dataFromBookingForm) {
                const error = validator(
                  dataFromBookingForm[field as keyof IBooking]
                );
                if (error) {
                  console.log("quick form validation error");
                  console.log(error);
                  newErrors[field as BookingFieldId] = t(error);
                  break;
                }
              } else {
                Object.entries(allBookingsDatas).forEach(([id, data]) => {
                  const error = validator(data[field as keyof IBooking]);
                  if (error) {
                    console.log("quick form validation error");
                    console.log(error);
                    if (!newErrors[field as keyof IBooking]) {
                      newErrors[field as keyof IBooking] = {}; //create object
                    }
                    (newErrors[field as keyof IBooking] as BookingError)[id] =
                      t(error);
                  }
                });
              }
            }
          }
        });
    }

    if (Object.values(newErrors).some((v) => Boolean(v))) {
      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));
      openSnackbar(t("validationFailed"), "error");
      return false;
    }
    return true;
  };

  const handleAddNewBooking = () => {
    setAdding(true);
  };

  const onAddNewLine = () => {
    setAdding(false);
    reloadBookings();
  };

  const handleDeleteQuickFormBooking = async (id: ObjectId) => {
    await deleteQuickFormBooking(id);
    reloadBookings();
  };

  const handleReorderRows = async (oldIndex: number, newIndex: number) => {
    if (currentQuickForm) {
      setLoading(true);
      await reorderLines({
        quickFormId: currentQuickForm._id,
        oldIndex,
        newIndex,
      } as ReorderQuickFormRequest);

      reloadBookings();
      setLoading(false);
    }
  };

  const isOfftestQuickForm = () => currentQuickForm?.type == "oftest";

  return (
    <Loader
      color="primary"
      size={50}
      margin={10}
      loading={Boolean(
        loadingQuickForms["load"] ||
          loadingBookings["load"] ||
          loadingBookingFormData["load"]
      )}
    >
      <Grid className={styles.quickForm} container>
        <Box
          width="100%"
          display="flex"
          justifyContent="space-between"
          py={1}
          borderColor="divider"
          border={1}
          borderLeft={0}
          borderRight={0}
        >
          {!editing ? (
            <Grid item xs={7} sm={10} container spacing={1}>
              <Grid
                className={adding ? styles.disabledArea : ""}
                item
                style={{ position: "relative" }}
              >
                <Box
                  display={{ xs: "block", sm: "none" }}
                  width="300px"
                  height="28px"
                >
                  &nbsp;
                  {/* weird kludge for responsive design, this block pushes the other */}
                </Box>
                <ChooseMenu
                  color="default"
                  prefix={t("quickForm")}
                  items={quickForms.map(({ _id, name, type }) => ({
                    key: String(_id),
                    value: name,
                    type,
                  }))}
                  currentItem={
                    currentQuickForm
                      ? {
                          key: String(currentQuickForm._id),
                          value: currentQuickForm.name,
                        }
                      : { key: "", value: "" }
                  }
                  handleChoose={(item: any) =>
                    setCurrentQuickForm({
                      _id: item.key as unknown as ObjectId,
                      name: item.value,
                      type: item.type,
                    } as IQuickForm)
                  }
                  isLoading={Boolean(loadingQuickForms["load"])}
                  size="small"
                  boxProps={{
                    position: { xs: "absolute", sm: "relative" },
                    top: 0,
                  }}
                />
              </Grid>

              <Grid className={adding ? styles.disabledArea : ""} item>
                {quickFormBookings && quickFormBookings.items.length > 0 ? (
                  <Button
                    color="default"
                    variant="outlined"
                    startIcon={
                      <Loader loading={Boolean(loadingQuickForms["create"])}>
                        <Icon>add</Icon>
                      </Loader>
                    }
                    disabled={Boolean(loadingQuickForms["create"])}
                    onClick={handleCreateQuickForm}
                    size="small"
                    fullWidth
                  >
                    {t("createNew")}
                  </Button>
                ) : (
                  <Button
                    color="primary"
                    startIcon={<Icon>add</Icon>}
                    onClick={() => setAdding(true)}
                    size="small"
                    variant="outlined"
                    disabled={isOfftestQuickForm()}
                  >
                    {t("addLine")}
                  </Button>
                )}
              </Grid>
              <Grid className={adding ? styles.disabledArea : ""} item>
                <Button
                  color="default"
                  variant="contained"
                  onClick={handleEditing}
                  startIcon={<Icon>create</Icon>}
                  size="small"
                  fullWidth
                  disabled={isOfftestQuickForm()}
                >
                  {t("edit")}
                </Button>
              </Grid>
            </Grid>
          ) : (
            //editing
            <Box ml={1} clone>
              <Grid
                item
                xs={6}
                sm={12}
                container
                wrap="nowrap"
                spacing={1}
                justify="space-around"
              >
                <Grid item xs={10} container>
                  <Grid item justify="center">
                    <TextField
                      value={quickFormNewName}
                      onChange={(e) => setEditQuickFormName(e.target.value)}
                      placeholder={t("enterQuickFormName")}
                      className="MuiGrid-container MuiGrid-justify-xs-center"
                      inputProps={{
                        autoFocus: true,
                        onFocus: (e) => e.currentTarget.select(),
                      }}
                      size="small"
                    />
                  </Grid>

                  <Grid item>
                    <Button
                      variant="contained"
                      color="default"
                      onClick={handleEditQuickFormName} //edit qf name
                      startIcon={
                        <Loader
                          size={20}
                          loading={Boolean(loadingQuickForms["edit"])}
                        >
                          <Icon>check</Icon>
                        </Loader>
                      }
                      size="small"
                    >
                      {t("save")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="default"
                      startIcon={<Icon>clear</Icon>}
                      onClick={
                        creating
                          ? handleDeleteQuickForm
                          : () => setEditing(false)
                      }
                      size="small"
                    >
                      {t("cancel")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      color="default"
                      variant="contained"
                      startIcon={<Icon>delete</Icon>}
                      onClick={handleDeleteQuickForm}
                      size="small"
                    >
                      {t("deleteQuickForm")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}

          <Grid item xs={6} sm={4}>
            <FieldsMenu
              size="small"
              options={bookingFormData.fields
                .filter(
                  ({ id, required, readOnly }) =>
                    !required &&
                    !readOnly &&
                    !ignoredColumns.includes(id) &&
                    !id.includes("packages.")
                )
                .map(({ id }) => ({
                  id,
                  title: t(id),
                  value: !hiddenFields.includes(id),
                }))}
              onChoose={({ id }) => toogleHiddenField(id as BookingFieldId)}
            />
          </Grid>
        </Box>

        {local && !adding && (
          <Box m={1} position="absolute" top={60} right={0}>
            <Grid item container xs={12}>
              <Grid item container justifyContent="center" alignItems="center">
                <Tooltip title={t("tableView")}>
                  <IconButton onClick={() => setButtonsView(false)}>
                    <Icon
                      fontSize="large"
                      color={!buttonsView ? "primary" : "default"}
                    >
                      table_rows
                    </Icon>
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("buttonsView")}>
                  <IconButton onClick={() => setButtonsView(true)}>
                    <Icon
                      color={buttonsView ? "primary" : "default"}
                      style={{ fontSize: 45, marginTop: 1 }}
                    >
                      apps
                    </Icon>
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>
        )}

        {isOfftestQuickForm() && (
          <Box width={"100%"} pr={{ xs: 12, md: "20%" }}>
            <Typography variant="caption" noWrap={false}>
              {t("quickFormDisclaimer")}
            </Typography>
          </Box>
        )}

        {!editing && (
          <>
            {adding && currentQuickForm ? (
              <AddQuickFormBooking
                quickFormId={currentQuickForm._id}
                onSubmit={onAddNewLine}
                isLocal={local}
              />
            ) : (
              <>
                <Box width={"85%"} py={3}>
                  {quickFormBookings && quickFormBookings.items.length > 0 && (
                    <BookingForm
                      booking={bookingForm}
                      handleChangeField={handleChangeField}
                      loading={loading}
                      customersCustomerLookup={customersCustomerLookup}
                      pushCustomersCustomer={pushCustomersCustomer}
                      setBooking={setBooking}
                      setEnableCCLookup={setEnableCCLookup}
                      handleClearAddresses={handleClearAddresses}
                      errors={errors}
                      ignoredFields={ignoredColumns}
                      //validators={adding ? [] : validators}
                      fullWidth
                      hideRegularFields
                      hidePackagePickers
                      hideSaveButton
                    />
                  )}
                </Box>
                {buttonsView && local ? (
                  <ButtonsView
                    muiltiFormFields={muiltiFormFields}
                    booking={bookingForm}
                    initBookings={quickFormBookings}
                  />
                ) : width === "lg" || width === "xl" ? (
                  <Box mt={2}>
                    <TableForm
                      initBookings={quickFormBookings}
                      loading={loading}
                      handleSubmit={handleSubmit}
                      handleDelete={handleDeleteQuickFormBooking}
                      ignoredColumns={[...ignoredColumns, ...muiltiFormFields]}
                      handleReorderRows={handleReorderRows}
                    />
                  </Box>
                ) : (
                  <ItemsForm
                    loading={Boolean(loadingBookings["load"])}
                    initBookings={quickFormBookings}
                    ignoredColumns={[...ignoredColumns, ...muiltiFormFields]}
                    handleSubmit={handleSubmit}
                    handleDelete={deleteQuickFormBooking}
                    //currentQuickForm={currentQuickForm}
                  />
                )}
                <Box
                  bgcolor="white !important"
                  position="absolute !important"
                  right={10}
                  bottom={55}
                  clone
                >
                  <Button
                    color="primary"
                    startIcon={<Icon>add</Icon>}
                    onClick={handleAddNewBooking}
                    size="large"
                    variant="outlined"
                    disabled={isOfftestQuickForm()}
                  >
                    {t("addLine")}
                  </Button>
                </Box>
              </>
            )}
          </>
        )}
      </Grid>
    </Loader>
  );
};
